<template>
  <div>
    <v-main>
      <KHeader
        title="Barcode/QRCode Scanner Demo"
      />
      <v-container style="padding-top:30px;padding-bottom:120px;">
        <v-row>
          Result: {{ text }}
        </v-row>
        <v-row>
          <StreamBarcodeReader
            @decode="(a, b, c) => onDecode(a, b, c)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </v-row>
      </v-container>
      <KFooter
        :pages="[
        ]"
      />
    </v-main>
  </div>
</template>

<script>

import KFooter from '../components/KFooter'
import KHeader from '../components/KHeader'
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    KHeader, KFooter, StreamBarcodeReader
  },
  data: function () {
    return {
      text: "(result)",
    }
  },
  mounted: function(){
    console.log("mounted")
  },
  created: function(){
    console.log("created")
  },
  methods: {
    onDecode(result, b, c) {
      console.log("onDecode:", result, b, c);
      this.text = result;
    },
    onLoaded() {
      console.log("onLoaded");
    },
  }
}
</script>

<style scoped>
</style>
