import Vue from 'vue'
import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  vuetify,
  el: '#app',
  router,
  data: {
  },
  render: h => h(App)
}).$mount('#app')



