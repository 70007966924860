import Vue from 'vue'
import Router from 'vue-router'
import Home from '../pages/Home'
Vue.use(Router)
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

export default new Router({
  routes: [
    {
      path: '/',
      component: Home
    },
  ]
})
