<template>
  <v-app-bar
    app
    color="amber"
    dark
  >
    <v-toolbar-title style="font-weight: bold;">
      {{ title }}
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  components: {
  },
  props: {
    title:{
      type: String,
      required: false,
      default: ""
    },
  },
  data: function () {
    return {
    }
  },
  methods: {
    oncomplete (index) {
      this.$emit('progress', index)
    },
    onbutton () {
      this.$emit('button')
    }
  }
}
</script>
