import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import 'vue-snack/dist/vue-snack.min.css'
import VueSnackbar from 'vue-snack'
import VueTimers from 'vue-timers'
// import {Html5QrcodeScanner} from "html5-qrcode"
// import {Html5Qrcode} from "html5-qrcode"

Vue.use(Vuetify);
Vue.use(VueSnackbar, { time: 4000 })
Vue.use(VueTimers)

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
});


