<template>
  <v-footer
    height="85"
    :padless="true"
    :fixed="true"
  >
    <v-card
      flat
      tile
      width="100%"
      class="amber text-center"
    >
      <v-card-text>
        <v-btn
          v-for="(page,index) in pages"
          :key="index"
          class="mx-10"
          icon
          @click="onclick(index)"
        >
          <v-icon size="36px">
            {{ page.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-divider />
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>ckchan.co</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  components: {
  },
  props: {
    pages:{
      type: Array,
      required: false,
      default:new Array()
    },
  },
  data: function () {
    return {
    }
  },
  methods: {
    onclick (index) {
      this.$emit('click', index)
    }
  }
}
</script>
